<template>
  <div class="order-insert">
    <div class="title">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/order/list' }">订单列表</el-breadcrumb-item>
        <el-breadcrumb-item>新增订单</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <!-- 表单数据 -->
    <div class="myform">
      <el-form :model="orderForm" :rules="rules" ref="rulForm" class="demo-ruleForm">
        <div class="form-top">
          <el-form-item label="下单姓名：" prop="userName">
            <el-input class="form-input" size="small" v-model="orderForm.userName"></el-input>
          </el-form-item>
          <el-form-item label="下单手机号：" prop="telNumber" class="top-center">
            <el-input class="form-input" size="small" v-model.number="orderForm.telNumber"></el-input>
          </el-form-item>
          <el-form-item label="下单商品：" prop="goods_id">
            <el-select size="small" v-model="orderForm.goods_id" placeholder="请选择下单商品">
              <el-option v-for="item in goods_label" :key="item.value" :label="item.label" :value="item.value"></el-option>
            </el-select>
          </el-form-item>
        </div>
        <div class="form-button">
          <el-form-item>
            <el-button type="warning" size="mini" @click="submitForm">确定</el-button>
            <el-button size="mini" @click="resetForm">返回</el-button>
          </el-form-item>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
import { shopGoodsList, insertOrder } from '../../../request/api'
import { mapState } from 'vuex'

export default {
  data () {
    return {
      orderForm: {
        userName: '',
        telNumber: '',
        goods_id: ''
      },
      query_info: {
        chain_id: '',
        limit: 999,
        page: 1
      },
      rules: {
        telNumber: [
          { required: true, message: '请输入下单手机号码', trigger: 'blur' },
          { type: 'number', message: '手机号码必须是数字值', trigger: 'blur'}
        ],
        userName: [
          { required: true, message: '请输入下单姓名', trigger: 'blur' }
        ],
        goods_id: [
          { required: true, message: '请选择下单商品', trigger: 'blur' }
        ]
      },
      goods_label: []
    }
  },
  computed: {
    ...mapState('m_login', ['loginData'])
  },
  methods: {
    async submitForm () {
      this.$refs.rulForm.validate(valid => {
        if (!valid) {
          console.log('error submit!')
          return false
        }
      })
      if (
        this.orderForm.userName === '',
        this.orderForm.telNumber === '',
        this.orderForm.goods_id === ''
      ) {
        this.$message.warning('请完善字段信息')
        return
      }

      const res = await insertOrder(this.orderForm)
      if (res.status !== 200 || res.data.code !== '1000') {
        this.$message.error(res.data.msg)
        return
      }
      this.$message.success('添加成功')
      this.$router.push({ path: '/order/list' })
    },
    resetForm () {
      this.$router.back()
    },
    // 初始化数据
    async init () {
      const res = await shopGoodsList(this.query_info.chain_id, this.query_info.limit, this.query_info.page, '')
      if (res.status !== 200 || res.data.code !== '1000') this.$message.error(res.data.msg)
      this.goods_label = res.data.data.goodsList.map(element => {
        return {
          value: element.shop_goods_id,
          label: element.name
        }
      })
    }
  },
  mounted () {
    this.init()
  },
  created () {
    this.query_info.chain_id = this.loginData.chain_id
  }
}
</script>

<style lang="less" scoped>
.order-insert {
  margin: 10px;
  height: 100%;
  .title {
    padding: 10px;
    background: #fff;
    box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.16);
    border-radius: 0px 0px 0px 0px;
    opacity: 1;
    margin-bottom: 10px;
  }
  .myform {
    height: 100%;
    .demo-ruleForm {
      height: 100%;
      .form-top {
        background: #fff;
        padding: 22px;
        box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.16);
        border-radius: 0px 0px 0px 0px;
        opacity: 1;
        display: flex;
        .top-center {
          margin: 0 20px;
        }
        .form-input {
          width: 240px;
        }
      }
      .form-button {
        background: #fff;
        padding: 20px 0px 2px 20px;
        box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.16);
        border-radius: 0px 0px 0px 0px;
        opacity: 1;
        margin: 10px 0px;
      }
    }
  }
}
</style>